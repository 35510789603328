import React from "react";
import TARIFFS from "../../components/iframe/wa-oa-addon-paywall/index.content";
import useQueryWaIframeParams from "../../hooks/useQueryWaIframeParams";
import Layout from "../../layouts/LayoutIframe";
import Tariff from "../../components/iframe/wa-oa-addon-paywall/Tariff";
import "../../components/iframe/wa-oa-addon-paywall/index.scss";

const WaOaAddonPaywall = () => {
  const { addProps, isRendered, paramsLink } =
    useQueryWaIframeParams("wa-oa-addon");
  const { monthly, annual, lifetime } = TARIFFS;

  return isRendered ? (
    <Layout withTagManager={false} className="PgIWOAP" turnOnEventView>
      <p className="PgIWOAP__title">Pricing</p>
      <div className="PgIWOAP-PL">
        <Tariff {...monthly} {...addProps} paramsLink={paramsLink} />
        <Tariff {...annual} {...addProps} paramsLink={paramsLink} />
        <Tariff {...lifetime} {...addProps} paramsLink={paramsLink} />
      </div>
    </Layout>
  ) : null;
};

export default WaOaAddonPaywall;
