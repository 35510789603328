import { useCallback, useEffect, useState } from "react";
import { log } from "../helpers";
import { ERRORS } from "../helpers/errors";

const useQueryWaIframeParams = (paywall = "") => {
  const [paramsLink, setParamsLink] = useState("");
  const [addProps, setAddProps] = useState({ software: "WEB" });
  const [isRendered, setIsRendered] = useState(false);

  const updateProps = useCallback((args) => {
    setAddProps((prevState) => ({ ...prevState, ...args }));
  }, []);
  useEffect(() => {
    try {
      const urlParams = new URL(window.location.href).searchParams;
      const { url, ci, lang } = Object.fromEntries(urlParams);
      const addParams = {
        software: "WEB",
        ...(url ? { url } : {}),
        ...(ci ? { ci } : {}),
        ...(paywall ? { paywall: encodeURIComponent(paywall) } : {}),
      };
      lang && updateProps({ checkoutLanguage: lang });
      setParamsLink(`&${new URLSearchParams(addParams).toString()}`);
    } catch (error) {
      log(error, ERRORS.URL_ERRORS.ERROR_PARSING_URL_PARAMETERS, true);
    } finally {
      setIsRendered(true);
    }
  }, [updateProps]);

  return { addProps, isRendered, paramsLink };
};

export default useQueryWaIframeParams;
